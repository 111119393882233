import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios"

import { isString } from "./any"

//region Error Classes
class AxiosClientError<T = unknown, D = any> extends Error {
  code?: string
  message: string
  method: string
  path?: string
  response?: AxiosResponse<T, D>
  status?: number

  constructor(cause: AxiosError<T, D>) {
    const code = cause.code
    const method = cause.config?.method?.toUpperCase() ?? "UNKNOWN"
    const path = cause.config?.url
    const response = cause.response
    const status = cause.status

    const message = [method, path, status ? `-> ${status}` : `-> None`, code && `(${code})`].filter(Boolean).join(" ")
    super(message, { cause })

    Object.assign(this, { method, path, status, code, response, message })

    this.name = `AxiosClientError`
  }
}

class AppClientError extends AxiosClientError {
  constructor(cause: AxiosError) {
    super(cause)

    this.name = `AppClientError`
  }
}

class AdaClientError extends AxiosClientError {
  constructor(cause: AxiosError) {
    super(cause)

    this.name = `AdaClientError`
  }
}

class InternalClientError extends AxiosClientError {
  constructor(cause: AxiosError) {
    super(cause)

    this.name = `InternalClientError`
  }
}
//endregion

//region Client Declarations
/**
 * Provided urls are prefixed with `/admin/` for convenience and `X-CSRF-TOKEN` is set at request time.
 */
export const adaClient = axios.create({
  withCredentials: true,
  baseURL: "/admin/",
})

/**
 * Automatically sets CSRF token and instructs API to reply with camelCased bodies.
 */
export const appClient = axios.create({
  withCredentials: true,
  baseURL: "/",
  headers: { "Key-Inflection": "camel" },
})

export const iClient = axios.create({
  withCredentials: true,
  baseURL: "/api/i/",
  headers: { "Key-Inflection": "camel" },
})
//endregion

//region Middleware
adaClient.interceptors.request.use(attachXCSRFToken)
appClient.interceptors.request.use(attachXCSRFToken)
iClient.interceptors.request.use(attachXCSRFToken)

adaClient.interceptors.request.use(forwardTracingContext)
appClient.interceptors.request.use(forwardTracingContext)
iClient.interceptors.request.use(forwardTracingContext)

appClient.interceptors.response.use(null, enhanceResponseError(AppClientError))
adaClient.interceptors.response.use(null, enhanceResponseError(AdaClientError))
iClient.interceptors.response.use(null, enhanceResponseError(InternalClientError))

appClient.interceptors.response.use(null, redirectAppAuthError)

/**
 * Attaches an X-CSRF-TOKEN header to an Axios request configuration if a CSRF token is found in the document's meta tags.
 *
 * @template D The type of the request data.
 * @param config The Axios request configuration object.
 * @returns The modified Axios request configuration object with the X-CSRF-TOKEN header attached.
 *
 * @throws {Error} If the request headers cannot be found or the CSRF token meta tag is missing.
 */
function attachXCSRFToken<D>(config: InternalAxiosRequestConfig<D>): InternalAxiosRequestConfig<D> {
  if (document instanceof Document) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute("content")

    if (isString(csrfToken) && config.headers) {
      config.headers["X-CSRF-TOKEN"] = csrfToken
    } else if (!config.headers) {
      console.error(new Error(`Trying to set X-CSRF-TOKEN, request header cannot be found`))
    } else {
      console.error(new Error(`meta[name="csrf-token"] could not be found!`))
    }
  }

  return config
}

/**
 * Adds tracing context headers and parameters to an Axios request configuration.
 *
 * This function extracts tracing information from the document's meta tags and the URL's query parameters,
 * and adds them to the Axios request configuration headers and parameters.
 *
 * @template D The type of the request data.
 * @param config The Axios request configuration to modify.
 * @returns The modified Axios request configuration with tracing context added.
 */
function forwardTracingContext<D>(config: InternalAxiosRequestConfig<D>): InternalAxiosRequestConfig<D> {
  if (document instanceof Document) {
    const traceId = document.querySelector('meta[name="trace-id"]')?.getAttribute("content")
    const spanId = document.querySelector('meta[name="span-id"]')?.getAttribute("content")
    const tracing = new URLSearchParams(window.location.search).get("tracing")

    if (traceId) config.headers["X-TRACE-ID"] = traceId
    if (spanId) config.headers["X-SPAN-ID"] = spanId
    if (tracing) config.headers["X-TRACING"] = tracing
  }

  return config
}

/**
 * Enhances an error by wrapping it in a specified error class if it is an instance of AxiosError.
 *
 * @param errorClass - The class to wrap the error in if it is an AxiosError.
 * @returns An Axios error interceptor function.
 */
function enhanceResponseError<T = unknown, D = any>(
  errorClass: typeof AxiosClientError<T, D>
): (error: Error) => Promise<Error> {
  return (error) => {
    if (error instanceof AxiosError) {
      return Promise.reject(new errorClass(error))
    }

    return Promise.reject(error)
  }
}

/**
 * Interceptor that redirects unauthorized errors from the app to the sign-in page.
 *
 * @param error- The underlying error to check for 401.
 * @returns A rejected promise of the underlying error.
 */
function redirectAppAuthError(error: Error): Promise<Error> {
  if (error instanceof AxiosClientError && error.status == 401) {
    window.location.href = "/users/sign_in"
  }

  return Promise.reject(error)
}
//endregion

export { AxiosClientError, AppClientError, AdaClientError, InternalClientError }
